.nomCategory td {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #676B78;
    text-transform: capitalize;
    cursor: pointer;
    padding: 12px 0;
    border-bottom: 1px solid #EBEDF0;
}

.nomCategory td:first-child,
.nomCategory td:last-child {
    border: none;
}

.popoverBtn div {
    text-align: right;
    padding-right: 8px;
}
