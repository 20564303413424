.table {
    background: #f7f8fa;
    border-spacing: unset;
    border-collapse: separate;
}

.tableThead tr th {
    border-top: 1px solid #F7F8FA;
    background: #fff;
    color: #676B78;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    padding: 12px 26px;
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #F7F8FA;
    border-bottom: 1px solid #FFFFFF;
}

.tableThead tr th:first-child,
.tableThead tr th:last-child {
    width: 16px;
    padding: 0;
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}

.tableThead tr .sortable {
    cursor: pointer;
}

.tableThead tr .sortable:hover {
    color: #202227;
    border-color: #E1E5EB;
}

.sortable.sorted {
    background: #F7F8FA;
}

.blankTr td {
    border-top: none;
    padding: 0;
    height: 8px;
}
