html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Noto Sans';
  src: local('NotoSans'), url('./assets/fonts/noto-sans/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Bold';
  src: local('NotoSansBold'), url('./assets/fonts/noto-sans/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans BoldItalic';
  src: local('NotoSansBoldItalic'), url('./assets/fonts/noto-sans/NotoSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans Italic';
  src: local('NotoSansItalic'), url('./assets/fonts/noto-sans/NotoSans-Italic.ttf') format('truetype');
}

h1, h2, h3, h4, p {
  margin: 0;
  padding: 0;
}

h1 {
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  color: #202227;
}

.layout {
  position: relative;
  width: 100%;
  height: 100%;
  background: #F7F8FA;
}

.layout button,
.layout button:focus {
  outline: none;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
}

.layout.menu-open .content {
  width: calc(100% - 80px);
  margin-left: 80px;
}

.c-btn {
  height: 40px;
  border: none;
  border-radius: 4px;
  font-family: Noto Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  padding: 12px 16px;
  background: none;
}

.c-btn:focus {
  outline: none;
}

.c-btn_text-gray {
  color: #676B78;
}

.c-btn.btn-add {
  background-image: url("./assets/icons/plus-icon.svg");
  background-repeat: no-repeat;
  background-position: 15px center;
  width: 140px;
  text-align: right;
  transition: background-color 0.2s ease-in-out;
}

.c-btn.btn-update {
  text-align: right;
  transition: background-color 0.2s ease-in-out;
}

.c-btn.bg-blue {
  background-color: #0192F9;
  color: #fff;
}

.c-btn.bg-blue:hover {
  background-color: #20A2FF;
  transition: background-color 0.2s ease-in-out;
}

.c-btn.bg-blue.disabled {
  background-color: #F7F8FA;
}

.c-btn__textless {
  width: 40px;
  height: 40px;
  padding: 8px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.5s ease-out;
  border-radius: 3px;
}

.c-btn__brd-gray {
  border: 1px solid #EBEDF0;
}

.c-btn__brd-gray:hover {
  background: #EBEDF0;
  transition: background 0.5s ease-out;
}

.c-btn.bg-light {}

.c-btn.bg-gray {

}

.c-btn.c-btn-sl {}

.c-btn.c-btn-md {}

.c-btn.c-btn-lg {}

.clr-gray {

}

.icon-block {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.icon {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.icon.icon-clock {
  background-image: url('./assets/icons/icon-clock.svg');
}

.icon.icon-check {
  background-image: url('./assets/icons/icon-check.svg');
}

.icon.icon-sub-menu {
  background-image: url('./assets/icons/icon-sub-menu.svg');
}

.icon.icon-table-view {
  background-image: url('./assets/icons/icon-table-view.svg');
}

.icon.icon-arrow {
  background-image: url('./assets/icons/icon-arrow.svg');
}

.section-actions {
  padding-top: 20px;
  padding-bottom: 16px;
  background: #FFFFFF;
}

.section-options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.section-options button:first-child {
  margin-right: 16px;
}

.section-options button {
  margin-right: 8px;
}

.section-options button:last-child {
  margin-right: 0;
}

.c-btn.dropdown-toggle {
  color: #676B78;
}

.c-btn.dropdown-toggle::after {
  margin-left: 6px;
}
