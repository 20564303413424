.popoverActionList {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.popoverActionList li {
    padding: 8px 16px;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #676B78;
    cursor: pointer;
}

.popoverActionList li:hover {
    background: #EBEDF0;
    transition: background 0.5s ease-out;
}
