.addSection td {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #202227;
    padding-top: 17px;
    padding-bottom: 19px;
    cursor: pointer;
}

.addSection:hover td {
    background: #E6E9ED;
}

.addSection td svg {
    margin-right: 15px;
    height: 15px;
    width: 15px;
}

.addSectionButton {
    display: flex;
}
