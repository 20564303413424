.nomItem td {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #202227;
    background: #FFFFFF;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 18px;
    padding-bottom: 18px;
    border-top: none;
    border-left: 1px solid #F7F8FA;
    border-bottom: 1px solid #F7F8FA;
}

.nomItem td:first-child,
.nomItem td:last-child {
    background: #F7F8FA;
    width: 16px;
}

.nomItemBlank td {
    cursor: pointer;
}

.nomItemBlank td:hover {
    background: #F2F3F7;
}

.nomItemBlank td:hover input {
    background: #F2F3F7;
}

.nomItemBlank td input {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    border: none;
    outline: none;
    width: 100%;
}

.nomItemBlank td input::placeholder {
    color: #676B78;
}

.nomItemBlank td input:focus {
    background: #FFF;
}

.nomItemBlank .addItem {
    padding: 0;
    text-align: center;
    cursor: pointer;
}

.addItem svg path {
    fill: #676B78;
}

.nomItemBlank .addItem:hover {
    background: #FFF;
}

.addItem:hover svg path {
    fill: #20A2FF;
}

.nomItem .deleteItem {
    cursor: pointer;
    text-align: center;
    padding: 0;
}

.deleteItem:hover svg path {
    fill: #20A2FF;
}

.rowEditable td input {
    border: 1px solid #FFF;
    border-radius: 3px;
    padding-left: 5px;
}

.rowEditable td:hover input {
    border: 1px solid #afafaf;
    border-radius: 3px;
}

.rowEditable td input:focus {
    border: 1px solid #afafaf;
}

.nomItemBlank .rowActions {
    padding: 0;
}

.rowActions svg path {
    fill: #676B78;
}

.rowActions svg path {
    fill: #676B78;
}

.rowActionsBtns {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
}

.rowActionAdd:hover svg path,
.rowActionDelete:hover svg path {
    fill: #20A2FF;
}
