.kanban-column {
    height: 100vh;
}

.column-header {
    margin-bottom: 16px;
}

.column-title h3 {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #202227;
}

.column-body {
    height: 100%;
    padding: 8px;
    background: #F7F8FA;
    border-radius: 6px;
}
