.nomSubCategory td {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #787c87;
    text-transform: capitalize;
    cursor: pointer;
    background: #dedede;
    padding: 12px 0;
    border: none;
}
