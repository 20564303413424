.user-info__name {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #202227;
    margin-bottom: 2px;
}

.user-info__position {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    color: #676B78;
}
