.login-page {
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
}

.login-form-side {
    padding: 40px 80px;
    flex: 0 0 30%;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
}

.gray-side {
    background: #F7F8FA;
    border-radius: 8px 0 0 8px;
    flex: 1;
}

.login-heading {
    margin-bottom: 36px;
}

.login-description {
    margin-bottom: 56px;
}

.login-description p {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #202227;
}

.login-button button {
    width: 153px;
    height: 60px;
    background: #0192F9;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    outline: none;
    position: relative;
}

.login-button button:before {
    position: absolute;
    content: '';
    width: 80%;
    height: 100%;
    top: 35%;
    left: 10%;
    background: #20A2FF;
    opacity: 0.5;
    filter: blur(20px);
}

.login-button button span {
    position: relative;
}

.login-button button:hover {
    background: #20A2FF;
    transition: background 0.5s ease-out;
}

.login-help p {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #676B78;
}

.login-help a {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #202227;
}
