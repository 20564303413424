.request-row td:first-child,
.request-row td:last-child {
    width: 16px;
    padding: 0;
    border: none;
}

.request-row td {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #202227;
    background: #fff;
    padding: 12px 24px;
    cursor: pointer;
    vertical-align: middle;
    border-top: none;
    border-bottom: 1px solid #F7F8FA;
}

.request-row td:hover {
    color: #0192F9;
}

.request-row:first-child td {
    border-top: 6px solid #F7F8FA;
}

.request-row:last-child td {
    border-bottom: 6px solid #F7F8FA;
}

.request-row td:first-child {
    /*border-left: 16px solid #F7F8FA;*/
    background: #F7F8FA;
}

.request-row td:last-child {
    /*border-right: 16px solid #F7F8FA;*/
    background: #F7F8FA;
}

.request-row td:not(:last-child) {
    border-right: 1px solid #F7F8FA;
}

.request-manager > div {
    display: flex;
    align-items: center;
}

.request-row .request-category div {
    padding: 6px 8px;
    border-radius: 4px;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: inline-block;
}

.request-row .request-category.interior div {
    background: #EDFAEE;
    color: #68D48B;
}

.request-row .request-category.facade div {
    background: #FFF6F2;
    color: #FD7036;
}

.request-row .request-category.floor div {
    background: #EEFAFB;
    color: #44CDC6;
}

.request-manager .user-avatar {
    margin-right: 12px;
    width: 28px;
    height: 28px;
}

.request-status {
    position: relative;
}

.request-status div {
    position: absolute;
    width: 4px;
    height: 16px;
    top: calc(50% - 8px);
    left: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.status-new {
    background: #68D48B;
}

.status-pending {
    background: #FD7036;
}

.status-in-progress {
    background: #0192F9;
}
