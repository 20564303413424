.nomRootRow td {
    border: none;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #202227;
    text-transform: capitalize;
    padding: 12px 0;
    border-bottom: 1px solid #EBEDF0;
}

.nomRootRow td:first-child,
.nomRootRow td:last-child {
    border: none;
}


.nomBlankTr td {
    padding: 0;
    height: 28px;
}
