.formControl {
    display: flex;
    flex-flow: column wrap;
    padding-top: 20px;
}

.formControl label {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #202227;
}

.formControl input[type=text] {
    background: #F2F3F7;
    border-radius: 4px;
    height: 56px;
    padding: 18px 24px;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #676B78;
    border: none;
}

.formControl input[type=text]:focus {
    outline: #1991ff;
}
