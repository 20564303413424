.table {
    background: #f7f8fa;
    border-spacing: unset;
    border-collapse: separate;
    table-layout: fixed;
    width: 100%;
}

.tableThead tr th {
    border-top: 1px solid #F7F8FA;
    background: #fff;
    color: #676B78;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    padding: 12px 14px;
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #F7F8FA;
    border-bottom: 1px solid #FFFFFF;
    width: 100%;
}

.tableThead tr:first-child th:first-child,
.tableThead tr:first-child th:last-child {
    width: 16px;
    padding: 0;
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}

.nomBlankTr td {
    padding: 0;
    height: 28px;
}

.addSectionModal .ant-modal-content {
    border-radius: 4px;
}

.addSectionModal .ant-modal-content .ant-modal-header {
    border-radius: 4px 4px 0 0;
    border: none;
}

.addSectionModal .ant-modal-content .ant-modal-close {
    display: none;
}
