.kanban-card {
    position: relative;
    margin-bottom: 8px;
}

.kanban-card:last-child {
    margin-bottom: 0;
}

.kanban-card:before {
    position: absolute;
    content: '';
    width: 80%;
    height: 100%;
    top: 35%;
    left: 10%;
    background: #202227;
    opacity: 0;
    filter: blur(20px);
    transition: opacity 0.2s ease-in-out;
}

.kanban-card:hover:before {
    opacity: .1;
    transition: opacity 0.2s ease-in-out;
    -moz-transition: opacity .2s ease-out;
    -webkit-transition: opacity .2s ease-out;
    -o-transition: opacity .2s ease-out;
}

.kanban-card-content {
    padding: 16px;
    background: #FFFFFF;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

.kanban-card-content > div {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.kanban-card .kanban-card__top {
    margin-bottom: 14px;
}

.task-id {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #202227;
}

.task-created-date {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    color: #676B78;
}

.request-category {
    padding: 6px 8px;
    background: #EEFAFB;
    border-radius: 4px;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #44CDC6;
}

.task-updated-at,
.check-list {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: #676B78;

}

.check-list {

}

.contractor {

}

.icon-block .icon {
    margin-right: 6px;
}
