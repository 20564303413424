.app-navigation {
    border-bottom: 1px solid #F7F8FA;
}

.page-header {
    height: 71px;
    background: #FFFFFF;
}

.page-title {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.page-title h1 {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #202227;
    margin-bottom: 0;
    margin-left: 12px;
}

.menu-btn {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    outline: none;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.5s ease-out;
}

.menu-btn:hover {
    background: #EBEDF0;
    transition: background 0.5s ease-out;
}

.user-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row wrap;
}
