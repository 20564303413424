.modalTitle {
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #202227;
}

.modalCancelBtn {
    height: 40px;
    border: none;
    border-radius: 4px;
    font-family: Noto Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    padding: 12px 16px;
    background: none;
    color: #676B78;
}

.modalCancelBtn:focus {
    outline: none;
}

.modalCancelBtn:hover {
    background: #EBEDF0;
    border-radius: 4px;
    color: #202227;
}
