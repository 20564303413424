.sidebar {
    background: #F7F8FA;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 999;
}

.sidebar_thin {
    width: 80px;
}

.sidebar__logo {
    padding: 16px 20px;
    border-bottom: 1px solid #EBEDF0;
}

.sidebar__logo_container {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar__navigation {
    padding-top: 20px;
}

.sidebar__nav-block {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.sidebar__nav-item {
    cursor: pointer;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin: 0 20px 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease-out;
    position: relative;
}

.sidebar__nav-item .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar__nav-item svg {
    fill: #676B78;
}

.sidebar__nav-item:hover {
    background: #EBEDF0;
    transition: background 0.2s ease-out;
}

.sidebar__nav-item:hover svg {
    fill: #202227;
}

.sidebar_thin .sidebar__nav-title {
    position: absolute;
    top: 6px;
    left: 48px;
    background: rgba(32, 34, 39, 0.75);
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 13px;
    line-height: 16px;
    width: max-content;
    padding: 6px 8px;
    display: none;
    transition: opacity 0.2s ease-out;
}

.sidebar_thin .sidebar__nav-item:hover .sidebar__nav-title {
    display: block;
    transition: opacity 0.2s ease-out;
}

.sidebar__nav-block a.active .sidebar__nav-item {
    background: #0192F9;
}

.sidebar__nav-block a.active .sidebar__nav-item svg {
    fill: #FFFFFF;
}
