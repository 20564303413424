.nomenclature-section .table thead tr th {
    vertical-align: middle;
}

.nomenclature-section .table tbody tr td:nth-child(2) {
    padding-left: 0;
}

.nom-root td {
    border: none;
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #202227;
    text-transform: capitalize;
    padding-top: 28px;
}

.nom-category td {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #676B78;
    text-transform: capitalize;
    cursor: pointer;
}

.nom-category td:first-child {
    border: none;
}

.nom-category__sub {
    font-family: 'Noto Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #787c87;
    text-transform: capitalize;
    cursor: pointer;
    background: #dedede;
}

.nom-category__sub td {
    border: none;
}

.sub-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nom-row td:first-child,
.nom-row td:last-child {
    width: 16px;
    padding: 0;
}

.nom-row-blank {
    height: 8px;
    border: none;
}

.nom-row .sec-arrow {
    width: 0;
    height: 0;
    border-top: 3px solid transparent;
    border-top-left-radius: 2px;
    border-bottom: 3px solid transparent;
    border-bottom-left-radius: 2px;
    border-left: 4px solid #676B78;
    display: inline-block;
    margin-left: 2px;
    margin-right: 8px;
    transition: 0.2s ease-in-out;
}

.nom-row.open .sec-arrow {
    transform: rotate(90deg);
    transition: 0.2s ease-in-out;
}

.table-container {
    overflow-x: scroll;
}
