@import '~antd/dist/antd.less';

.ant-modal-content {
  border-radius: 4px;
  padding: 24px 32px 40px;
}

.ant-modal-content .ant-modal-close {
  display: none;
}

.ant-modal-content .ant-modal-header {
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  padding: 0;
  margin-bottom: 8px;
}

.ant-modal-content .ant-modal-body {
  padding: 0;
}

.ant-modal-content .ant-modal-footer {
  margin-top: 28px;
  padding: 0;
  border-top: none;
  text-align: left;
}

.ant-popover-content .ant-popover-arrow {
  display: none;
}

.ant-popover-inner-content {
  border-radius: 4px;
  padding: 6px 0;
}

